import { useEffect, useRef, forwardRef } from 'react'
import ReactSelect, { components } from "react-select"

// ** Styles Imports
import '@styles/react/libs/react-select/_react-select.scss'
import 'animate.css/animate.css'
import '@styles/base/plugins/extensions/ext-component-sweet-alerts.scss'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

export const showMessageBox = (fireInfo) => {
    return MySwal.fire(fireInfo)
}

export function StringPad(string, length, padString = '0') {
    let result = string
    while (result.length < length) {
        result = padString + result
    }
    return result
}

// ** data time format
// YYYY/M/D
export const getFormatedDate = () => {
    const today = new Date()
    return `${today.getFullYear()}/${(today.getMonth() + 1)}/${today.getDate()}`
}

// YYYY-MM-DD
export function getFormatedDateForInput(target = new Date()) {
    const year = target.getFullYear()
    const month = StringPad(`${target.getMonth() + 1}`, 2)
    const day = StringPad(`${target.getDate()}`, 2)
    return `${year}-${month}-${day}`
}

//YYYYMMDDhhmmss
export function getFullDateTime(target = new Date()) {
    const year = target.getFullYear()
    const month = StringPad(`${target.getMonth() + 1}`, 2)
    const day = StringPad(`${target.getDate()}`, 2)
    const hour = StringPad(`${target.getHours()}`, 2)
    const min = StringPad(`${target.getMinutes()}`, 2)
    const sec = StringPad(`${target.getSeconds()}`, 2)
    return year + month + day + hour + min + sec
}

//MM/DD hh:mm:ss
export function getFullDateTimeTW(target = new Date()) {
    const year = target.getFullYear()
    const month = StringPad(`${target.getMonth() + 1}`, 2)
    const day = StringPad(`${target.getDate()}`, 2)
    const hour = StringPad(`${target.getHours()}`, 2)
    const min = StringPad(`${target.getMinutes()}`, 2)
    const sec = StringPad(`${target.getSeconds()}`, 2)
    return `${month}/${day} ${hour}:${min}:${sec}`
}

//hh:mm:ss
export function getFullTimeTW(target = new Date()) {
    const hour = StringPad(`${target.getHours()}`, 2)
    const min = StringPad(`${target.getMinutes()}`, 2)
    const sec = StringPad(`${target.getSeconds()}`, 2)
    const sss = StringPad(`${target.getMilliseconds()}`, 3)
    return `${hour}:${min}:${sec}`
}

//YYYYMMDD to YYYY-MM-DD
export const convertDateStringForInput = (target) => {
    // const fullDate = target || getFullDate()
    const fullDate = target
    // return ${fullDate.substring(0, 4)}-${fullDate.substring(4, 6)}-${fullDate.substring(6, 8)}
    if (fullDate && fullDate.length === 8) {
        return `${fullDate.substring(0, 4)}-${fullDate.substring(4, 6)}-${fullDate.substring(6, 8)}`
    } else {
        return ''
    }
}

//YYYYMMDD
export function getFullDate(target = new Date()) {
    const year = target.getFullYear()
    const month = StringPad(`${target.getMonth() + 1}`, 2)
    const day = StringPad(`${target.getDate()}`, 2)
    return year + month + day
}

export const calcSameDay = (dateString, yearShift) => {
    const date = new Date(dateString)
    const newDate = new Date(date.getFullYear() + yearShift, date.getMonth(), date.getDate())
    let nowDay = date.getDay()
    const newDay = newDate.getDay()
    if (nowDay < newDay) {
        nowDay = nowDay + 7
    }
    const shiftDay = nowDay - newDay
    return (
        new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() + shiftDay)
    )
}

export const addDays = (date, addDays) => {
    const result = new Date(date)
    result.setDate(result.getDate() + addDays)
    return result
}

export const addYears = (date, addYears) => new Date(date.getFullYear() + addYears, date.getMonth(), date.getDate())

export const calcShiftDays = (startDate, endDate) => Math.abs((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24))

export function useInterval(callback, delay) {
    const savedCallback = useRef()
    //Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    //Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current()
        }
        if (delay !== null) {
            tick()
            const id = setInterval(tick, delay)
            return () => {
                clearInterval(id)
            }
        }
    }, [callback, delay])
}

// ** Bootstrap Checkbox Component
export const BootstrapCheckbox = forwardRef(({ onClick, ...rest }, ref) => (
    <div className='custom-control custom-checkbox'>
        <input type='checkbox' className='custom-control-input' ref={ref} {...rest} />
        <label className='custom-control-label' onClick={onClick} />
    </div>
))

// ** MultiSelect base on ReactSelect
export const MultiSelectComponentOption = ({ data, ...props }) => {
    return (
        <components.Option {...props}>
            <div className='d-flex flex-wrap align-items-center'>
                <div>{data.label}</div>
            </div>
        </components.Option>
    )
}

export function isMobileDevice() {
    // const mobileDevice = ['Android', 'webOS', 'iPhone', 'iPad', 'iPod', 'BlackBerry', 'Windows Phone']
    const mobileDevice = ['Android', 'webOS', 'iPhone']
    const isMobileDevice = mobileDevice.some(e => navigator.userAgent.match(e))
    return isMobileDevice
}

export const agGrid_autoSizeAllColumns = (gridColumnApi, skipHeader) => {
    //if (isMobileDevice()) return
    const allColumnIds = []
    if (!gridColumnApi || !gridColumnApi.getAllColumns()) return

    gridColumnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId)
    })
    gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
}

//below regular expression not support in Mac / Iphone....   Moon @ 2021/11/26
//https://www.codeproject.com/Questions/5274806/Regex-works-in-chrome-but-breaks-in-safari-invalid 
//export const NumberCommasFormat = num => num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
// export const NumberCommasFormat = num => num.toLocaleString()
export const NumberCommasFormat = num => {
    return (num === undefined || num === null) ? '' : num.toLocaleString()
}

export const NumberWanDallorFormat = num => {
    let result = ''
    if (num > 9999) {
        result = `${NumberCommasFormat((num / 10000).toFixed(2))}萬`
    } else {
        result = NumberCommasFormat(num)
    }
    return result
}

export function NumberWanDallorFixedFormat(num, fixed = 2) {
    return `${NumberCommasFormat((num / 10000).toFixed(fixed))}`
}

export function arrayFilterByValue(array, string) {
    return array.filter(o => Object.keys(o).some(k => o[k].toString().toLowerCase().includes(string.toString().toLowerCase())))
}

// for ag-grid valueFormatter
export const valueFormatterFunction = m => NumberCommasFormat(m.value)
export const valueFormatterWanDallorFunction = m => NumberWanDallorFixedFormat(m.value)
export const valueFormatterPercentageFunction = m => `${NumberCommasFormat(m.value)}%`

export function clearEscapeSpecialChars(jsonString) {
    return jsonString.replace(/\n/g, "")
        .replace(/\r/g, "")
        .replace(/\t/g, "")
        .replace(/\f/g, "")
}

export const getAgGridColumnsFormat = (columnsString, isShowWanDallor) => {
    isShowWanDallor = (isShowWanDallor === undefined) ? false : isShowWanDallor
    const objectList = JSON.parse(clearEscapeSpecialChars(columnsString))
        .map(obj => {
            if (Object.keys(obj).find(key => key === "valueFormatter")) {
                if (obj.valueFormatter.toLowerCase() === "wandallor" && isShowWanDallor) {
                    obj.valueFormatter = valueFormatterWanDallorFunction
                    obj.headerName = `${obj.headerName}(萬)`
                } else if (obj.valueFormatter.toLowerCase() === "percentage") {
                    obj.valueFormatter = valueFormatterPercentageFunction
                } else {
                    obj.valueFormatter = valueFormatterFunction
                }
            }

            return obj
        })
    return objectList
}
