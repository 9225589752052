// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { useContext } from 'react'
import axios from 'axios'
import { getUserData } from '@utils'
import { useSelector, useDispatch } from 'react-redux'

// ** CityApp Utilty
import { ShowToast } from '@CityAppExtComponents/caToaster'
import { showMessageBox } from '@CityAppHelper'

import useJwt from '@src/auth/jwt/useJwt'


const sliceName = 'AccountSetting'

export const kickOutUser = createAsyncThunk(`${sliceName}/kickOutUser`, async (params, { rejectWithValue }) => {
    try {

        const userList = params.userList


        const operator_userId = params.operator_userId
        const operator_password = params.operator_password
        const operator_companyId = params.operator_companyId

        const config = useJwt.jwtConfig
        const doLoginSuccess = res => {
            // const dispatch = useDispatch()
            // const ability = useContext(AbilityContext)

            //const data = { ...res.data.userData, accessToken: res.data.accessToken, refreshToken: res.data.refreshToken }
            //dispatch(handleLogin(data))
            localStorage.setItem('userData', JSON.stringify(res.data.userData))
            localStorage.setItem(config.storageTokenKeyName, JSON.stringify(res.data.accessToken))
            localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(res.data.refreshToken))

            // ability.update(res.data.userData.ability)

        }
        const doLoginFaliure = err => {
            console.log(err)
            showMessageBox({
                title: '登入失敗，請幫我重新登入謝謝',
                icon: 'error',
                timer: 5000,
                customClass: {
                    confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            })
            document.location.href = "/login"
            console.log('doLoginFaliure')
        }
        userList.forEach(async element => {

            const userId = element.userId
            const password = element.password
            const companyId = element.companyId

            await useJwt.login({ companyId, userId, password, force: true })
        })


        await useJwt.login({ companyId: operator_companyId, userId: operator_userId, password: operator_password, force: true })
            .then(res => {
                doLoginSuccess(res)
                console.log('success')
            }).catch(err => {
                doLoginFaliure(err)
                console.log(err)
            })
    } catch (err) {
        console.log(err)
    }

})


export const usersDataGetList = createAsyncThunk(`${sliceName}/usersDataGetList`, async (params, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/api/${sliceName}/GetUserList`, params)
        return {
            params,
            data: response.data.data
        }
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

export const getUserGroupData = createAsyncThunk(`${sliceName}/getUserGroupList`, async (params, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/api/${sliceName}/GetUserGroupList`, params)
        return {
            params,
            data: response.data.data
        }
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

export const getGroupProgramDetail = createAsyncThunk(`${sliceName}/getGroupProgramDetail`, async (params, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/api/${sliceName}/GetGroupProgramDetail`, params)
        return {
            params,
            data: response.data.data
        }
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

export const addUsersData = createAsyncThunk(`${sliceName}/addUsersData`, async (params, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/api/${sliceName}/AddUsers`, params)

        return {
            params,
            data: response.data.data
        }
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

export const updUsersData = createAsyncThunk(`${sliceName}/updUsersData`, async (params, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/api/${sliceName}/UpdUsers`, params)
        return {
            params,
            data: response.data.data
        }
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

export const delUsersData = createAsyncThunk(`${sliceName}/delUsersData`, async (params, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/api/${sliceName}/DelUsers`, params)
        return {
            params,
            data: response.data.data
        }
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

export const addUserGroupData = createAsyncThunk(`${sliceName}/addUserGroupData`, async (params, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/api/${sliceName}/AddUserGroup`, params)
        return {
            params,
            data: response.data.data
        }
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

export const delUserGroupData = createAsyncThunk(`${sliceName}/delUserGroupData`, async (params, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/api/${sliceName}/DelUserGroup`, params)
        return {
            params,
            data: response.data.data
        }
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

export const updUserGroupData = createAsyncThunk(`${sliceName}/updUserGroupData`, async (params, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/api/${sliceName}/UpdUserGroup`, params)
        return {
            params,
            data: response.data.data
        }
    } catch (err) {
        if (!err.response) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})


export const AccountSettingSlice = createSlice({
    name: sliceName,
    initialState: {
        userData: {
            data: [],
            queryHeader: {
                user_name: '',
                name: '',
                companyID: getUserData()?.companyid ?? ''
            },
            empData: []
        },
        userGroupData: {
            data: []
        },
        groupProgramDetail: {
            data: [],
            addModeData: []
        },
        isLoading: {
            userData: false,
            userGroupData: false,
            groupProgramDetail: false
        },
        currentMode: 'query'
    },
    reducers: {
        toggleIsLoading(state, { payload }) {
            const { status, field } = payload
            state.isLoading[field] = status
        },
        updQueryHeader(state, { payload }) {
            const { name, value } = payload
            state.userData.queryHeader[name] = value
        },
        getAddModeData(state, { payload }) {
            state.groupProgramDetail.data = state.groupProgramDetail.addModeData
        }
    },
    extraReducers: builder => {


        builder
            //usersDataGetList
            .addCase(usersDataGetList.pending, (state, action) => {
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: true, field: 'userData' } })
            })
            .addCase(usersDataGetList.rejected, (state, action) => {
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: false, field: 'userData' } })
            })
            .addCase(usersDataGetList.fulfilled, (state, action) => {
                const { userList } = action.payload.data
                state.userData.data = userList
                if (!userList || userList.length <= 0) {
                    ShowToast('使用者帳號 - 查詢', '查無資料', 'warning')
                }
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: false, field: 'userData' } })
            })

            //userGroupData
            .addCase(getUserGroupData.pending, (state, action) => {
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: true, field: 'userData' } })
            })
            .addCase(getUserGroupData.rejected, (state, action) => {
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: false, field: 'userData' } })
            })
            .addCase(getUserGroupData.fulfilled, (state, action) => {
                const { groupList } = action.payload.data

                state.userGroupData.data = groupList
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: false, field: 'userData' } })
            })

            //getGroupProgramDetail
            .addCase(getGroupProgramDetail.pending, (state, action) => {
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: true, field: 'groupProgramDetail' } })
            })
            .addCase(getGroupProgramDetail.rejected, (state, action) => {
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: false, field: 'groupProgramDetail' } })
            })
            .addCase(getGroupProgramDetail.fulfilled, (state, action) => {
                const rowData = action.payload.data

                state.groupProgramDetail.data = rowData.groupProgramDetail
                state.groupProgramDetail.addModeData = rowData.groupProgramDetail
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: false, field: 'groupProgramDetail' } })
            })

            //addUsersData
            .addCase(addUsersData.pending, (state, action) => {
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: true, field: 'userData' } })
            })
            .addCase(addUsersData.rejected, (state, action) => {
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: false, field: 'userData' } })
            })
            .addCase(addUsersData.fulfilled, (state, action) => {
                const rowData = action.payload.data.userList
                state.userData.data = rowData
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: false, field: 'userData' } })
                ShowToast('新增成功', '', 'success')
            })

            //updUsersData
            .addCase(updUsersData.pending, (state, action) => {
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: true, field: 'userData' } })
            })
            .addCase(updUsersData.rejected, (state, action) => {
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: false, field: 'userData' } })
            })
            .addCase(updUsersData.fulfilled, (state, action) => {
                const rowData = action.payload.data.userList
                state.userData.data = rowData

                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: false, field: 'userData' } })
                ShowToast('修改成功', '', 'success')
            })

            //delUsersData
            .addCase(delUsersData.pending, (state, action) => {
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: true, field: 'userData' } })
            })
            .addCase(delUsersData.rejected, (state, action) => {
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: false, field: 'userData' } })
            })
            .addCase(delUsersData.fulfilled, (state, action) => {
                const rowData = action.payload.data.userList
                state.userData.data = rowData
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: false, field: 'userData' } })
                ShowToast('刪除成功', '', 'success')
            })

            //addUserGroupDataUserGroupData
            .addCase(addUserGroupData.pending, (state, action) => {
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: true, field: 'userGroupData' } })
            })
            .addCase(addUserGroupData.rejected, (state, action) => {
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: false, field: 'userGroupData' } })
            })
            .addCase(addUserGroupData.fulfilled, (state, action) => {
                const rowData = action.payload.data.groupList
                state.userGroupData.data = rowData
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: false, field: 'userGroupData' } })
                ShowToast('新增成功', '', 'success')
            })

            //delUserGroupData
            .addCase(delUserGroupData.pending, (state, action) => {
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: true, field: 'userGroupData' } })
            })
            .addCase(delUserGroupData.rejected, (state, action) => {
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: false, field: 'userGroupData' } })
            })
            .addCase(delUserGroupData.fulfilled, (state, action) => {
                const rowData = action.payload.data.groupList
                state.userGroupData.data = rowData
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: false, field: 'userGroupData' } })
                ShowToast('刪除成功', '', 'success')
            })

            //updUserGroupData
            .addCase(updUserGroupData.pending, (state, action) => {
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: true, field: 'userGroupData' } })
            })
            .addCase(updUserGroupData.rejected, (state, action) => {
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: false, field: 'userGroupData' } })
            })
            .addCase(updUserGroupData.fulfilled, (state, action) => {
                const rowData = action.payload.data.usergroup
                state.userData.data = action.payload.data.users
                state.userGroupData.data = action.payload.data.usergroup
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: false, field: 'userGroupData' } })
                ShowToast('修改成功', '', 'success')
            })

            //kickOutUser
            .addCase(kickOutUser.pending, (state, action) => {
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: true, field: 'userData' } })
            })
            .addCase(kickOutUser.rejected, (state, action) => {
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: false, field: 'userData' } })
            })
            .addCase(kickOutUser.fulfilled, (state, action) => {
                AccountSettingSlice.caseReducers.toggleIsLoading(state, { payload: { status: false, field: 'userData' } })
            })
    }
})

export const { updQueryHeader, toggleIsLoading, getAddModeData } = AccountSettingSlice.actions
export const isLoading = state => state.accountSetting.isLoading
export default AccountSettingSlice.reducer
